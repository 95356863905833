import React from 'react'
import { AppPromoSection } from '../LandingView/AppPromoSection'

export const AppView: React.FC = () => {
  return (
    <main className="relative flex h-full min-h-screen w-full items-center justify-center bg-core-gray-950 pt-16 text-white">
      <AppPromoSection containerClassName="h-full !py-0" className="!py-0" />
    </main>
  )
}
